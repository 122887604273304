import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid, Switch, Typography } from "@material-ui/core";
// import AnimalDescription from "../AnimalDescription";
// import AnimalCharts from "../AnimalCharts";
import AddAnimals from "../AddAnimals";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import CustomMuiTable from "../../../components/CustomMuiTable";
import TableButtons from "../../../components/TableButtons";
import { animalRouteOptions } from "../constants";
import { columns } from "./constants";
import { ROUTES_DICT } from "../../../routes/routesDict";
import AnimalActions from "../../../redux/actions/animal.actions";
import { CircularProgress } from "@material-ui/core";

/**
 * @component
 * @description Componente, tabla que contiene la lista de animales
 * @author Emerson Puma Quispe <emerson.puma@ideascloud.io>
 */

const AnimalPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listAnimal = useSelector((state) => state.animal.list);
  const listAnimalDeads = useSelector((state) => state.animal.listDeads);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [listType, setListType] = useState(true);
  const [currentList, setCurrentList] = useState(listAnimal);

  useEffect(() => {
    (async () => {
      setTitle("Control Ganadero");
      setChipList(animalRouteOptions(location));
      if (!listAnimal || listAnimal.length === 0) {
        setIsLoading(true);
        await dispatch(AnimalActions.list());
      }
      if (!listAnimalDeads || listAnimalDeads.length === 0) {
        setIsLoading(true);
        await dispatch(AnimalActions.listDeads());
      }
      setIsLoading(false);
    })();
    console.log({ listType });
    if (listType) {
      setCurrentList(listAnimal);
    } else {
      setCurrentList(listAnimalDeads);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType]);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "identifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <TableButtons
            data={currentList[dataIndex]}
            onClickEyeButton={() => {
              history.push(
                generatePath(ROUTES_DICT.animalDetail.detail, {
                  ...params,
                  _id: currentList[dataIndex]._id,
                })
              );
            }}
            onClickDeleteButton={(e) => {
              history.push(
                generatePath(ROUTES_DICT.animal.delete, {
                  ...params,
                  _id: listAnimal[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.animal.update, {
                  ...params,
                  _id: listAnimal[dataIndex]._id,
                })
              );
            }}
            onClickStarButton={() => {
              dispatch(
                AnimalActions.update({
                  ...listAnimal[dataIndex],
                  isFeatured: !Boolean(listAnimal[dataIndex]?.isFeatured),
                })
              );
            }}
            starButtonFeatured={listAnimal[dataIndex]?.isFeatured}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      {/* <AnimalDes /> */}
      {/* <AnimalCh /> */}
      <AddAnimals searchText={searchText} setSearchText={setSearchText} />
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Animales Desactivados</Grid>
          <Grid item>
            <Switch
              checked={listType}
              onChange={(e) => {
                setListType(!listType);
              }}
            />
          </Grid>
          <Grid item>Animales Vivos</Grid>
        </Grid>
      </Typography>

      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listType ? listAnimal : listAnimalDeads}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};
export default AnimalPageList;
