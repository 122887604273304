import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import IdeasCloudApi from '../../helpers/ideascloudApi'
import { ROUTES_DICT } from '../../routes/routesDict'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import RegisterCard from '../../components/RegisterCard'
import Logo from '../../components/Logo'
import { useStyles } from '../LoginPage/styles'
import { Formik } from 'formik'
import TextFieldFormik from '../../components/Inputs/TextFieldFormik'
import { useEffect } from 'react'
import useQuery from '../../helpers/useQuery'
import NotFoundPage from '../NotFoundPage'
import PasswordFieldFormik from '../../components/Inputs/PasswordFieldFormik'
import PasswordValidation from '../../components/PasswordValidation'

const NewPasswordPage = props => {
	const classes = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()
	const queryParams = useQuery()
	const token = queryParams.get('token')


	const validationSchema = yup.object({
		password: yup.string().required("Ingresa tu nueva contraseña"),
		confirmPassword: yup.string().oneOf([yup.ref('password'), null], "La contraseña no coincide")

	})



	const onSubmit = async (values, actions) => {
		const toSend = {
			token: token,
			password: values.password
		}
		await IdeasCloudApi.fetch("userSetNewPassword", toSend, dispatch, "Se cambio su contraseña exitosamente, inicie sesión")
		actions.setSubmitting(false)
		history.push(ROUTES_DICT.login)
	}

	const NewPasswordForm = ({

		handleChange,
		handleSubmit,
		isSubmitting,
		resetForm,
		values,
		errors,
		touched,
	}) => (<form onSubmit={handleSubmit} className={classes.form}>
		<Grid container spacing={1}>
			<PasswordFieldFormik
				name="password"
				label='Nueva contraseña'
				onChange={handleChange}
			/>
			<PasswordFieldFormik
				name="confirmPassword"
				label='Repetir nueva contraseña'
				onChange={handleChange}
			/>
			<PasswordValidation value={values.password} />

			<Grid item xs={12}>
				<Button
					className={classes.recovery}
					disabled={isSubmitting}
					type="submit"
				>
					Reestablecer

				</Button>

			</Grid>

		</Grid>
	</form>)

	if (!token) return (
		<NotFoundPage />
	)

	return (
		<Container>
			<RegisterCard >
				<Logo customClasses={classes.logo} />
				<Grid item className={classes.titleContainer}>
					<Typography align='center' className={classes.title}>
						Nueva contraseña
					</Typography>
					<Typography
						variant='caption'
						gutterBottom
						align='center'
						className={classes.subtitle}
					>
						Ingresa la nueva contraseña de tu cuenta
					</Typography>
					<Formik
						initialValues={{ password: "", confirmPassword: "" }}
						onSubmit={onSubmit}
						validationSchema={validationSchema}
					>
						{(props) => <NewPasswordForm {...props} />}

					</Formik>

				</Grid>

			</RegisterCard>


		</Container>
	)
}

NewPasswordPage.propTypes = {}

export default NewPasswordPage